const Home = () => {
  return <div className="container text-center flex min-w-full grow">
  <div className="container bg-blue-200 place-content-center space-x-1 my-4 grow min-w-fit">
    <div className="flex-row justify-center items-center py-4 min-w-fit">
        <header className="font-medium leading-tight text-3xl mt-0 mb-2 text-indigo-900">
          <h3>Welcome to the Dashboard</h3>
        </header>
        </div>
        </div>
      </div>;
};

export default Home;
