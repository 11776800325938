import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import moment from 'moment/moment';


function Plotter(parentData) {
  let [asset, setAsset] = useState();

  let data = parentData.data;
  let quotes = parentData.quotes;
  let trades = parentData.trades;
  let Fast_Market_color = '#92400e' //tailwind amber-800
  let SMC_color = '#ea580c' // tailwind orange-600
  let warning_color = '#bef264' // tailwind lime-300
  let normal_color = '#16a34a' // tailwind green-600
  let criterion_colors = { 'Normal': normal_color, 'SMC': SMC_color, 'FM': Fast_Market_color, 'Warning': warning_color }

  useEffect(() => {

    let traces = []
    let maxy = -Infinity
    if (quotes && quotes.length > 0) {
      let dtime = []
      let bids = []
      let asks = []

      quotes.forEach(d => {
        dtime.push(moment(d.dtime).format("yyyy-MM-DDTHH:mm:ss"));
        bids.push(parseFloat(d.bid_price0));
        asks.push(parseFloat(d.ask_price0));
      })

      // Create the trace for the buys line
      const buysTrace = {
        x: dtime,
        y: bids,
        mode: 'lines',
        name: 'bid',
        line: { color: 'olive' },
      };

      // Create the trace for the sells line
      const sellsTrace = {
        x: dtime,
        y: asks,
        mode: 'lines',
        name: 'Sell',
        line: { color: 'orange' },
      };

      // Combine the two traces into an array
      traces.push(buysTrace)
      traces.push(sellsTrace)


      maxy = Math.max(...bids.filter(val => !Number.isNaN(val)).concat(asks.filter(val => !Number.isNaN(val))), maxy)

    }
    if (trades && trades.length > 0) {
      let dtime = []
      let tradearray = []

      trades.forEach(d => {
        dtime.push(moment(d.dtime).format("yyyy-MM-DDTHH:mm:ss"));
        tradearray.push(parseFloat(d.trade_price));
      })

      // Create the trace for the trades
      const tradesTrace = {
        x: dtime,
        y: tradearray,
        mode: 'markers',
        name: 'trades',
        line: { color: 'blue' },
      };



      // Combine the two traces into an array
      traces.push(tradesTrace)


      maxy = Math.max(...tradearray.filter(val => !Number.isNaN(val)), maxy)

    }





    if (data && data.length > 0) {
      const sortedData = data.sort((a, b) => new Date(a.dtime) - new Date(b.dtime));



      if (maxy === -Infinity) {
        maxy = 0;
      }

      sortedData.forEach((d, i) => {
        const tracey = {
          type: 'scatter',
          x: [moment(d.dtime).format("yyyy-MM-DDTHH:mm:ss"), moment(sortedData[i + 1]?.dtime ?? d.dtime).format("yyyy-MM-DDTHH:mm:ss")],
          y: [maxy + 1, maxy + 1],
          mode: 'lines',
          line: {
            color: criterion_colors[d.criterion],
            width: 15,
            dash: 'solid'
          },
          hoverinfo: 'none',
          showlegend: false
        };

        traces.push(tracey)


      });

      setAsset(traces);
    }

  }, [data, quotes, trades])



  const layout = {
    xaxis: {
      type: 'date',
      tickformat: '%H:%M'
    },
    yaxis: {
      //   range: [-1, 1],
      showticklabels: true
    },
    margin: {
      l: 50,
      r: 50,
      t: 50,
      b: 50
    }
  };

  return (
    <Plot
      data={asset}
      layout={layout}
    />
  );
}

export default Plotter;
