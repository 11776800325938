import React from 'react';
import { useState, useEffect } from 'react';
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import { getDefaultParametersData, setDefaultParametersData } from '../services/user.service';
import { customSort } from '../functions/sort.function';
import { useLocation, useParams } from 'react-router-dom';
import { DataGrid} from '@mui/x-data-grid';

registerLocale('es', es)



function EditParameters() {
    const [data, setData] = useState([]);
    const [origdata, setOrigData] = useState([]);
    const [updating, setUpdating] = useState('');

    let { partoedit } = useParams();
    let location = useLocation();

  
    const refresh = () => {
      getDefaultParametersData(partoedit)
        .then(res => {
          let dbdata = JSON.parse(res.data).params.sort((a, b) => customSort(a.product, b.product))
          setOrigData([...dbdata]);
          setData(dbdata)
        })
        .catch(err => console.log(err));
    }
  
    useEffect(() => {
      refresh()
    }, [location, partoedit]);


  
    const compareArrays = (original, currentData) => {
      const result = [];
      currentData.forEach(row => {
        let oriRow = original.find(x=> x.product === row.product)
        if (oriRow) {
          if(Object.keys(row).some(rowCol => row[rowCol]!==oriRow[rowCol])){
            result.push(row)
          }
        }
      })
      return result;
    }

    
    const handleClick = ()=> {
      let valueUpdater = compareArrays(origdata, data);
      if (valueUpdater.length > 0){
        setUpdating(`Actualizando parámetros a las ${new Date().toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })}.`)
        setDefaultParametersData(valueUpdater).then(response => {
          setUpdating(`Respuesta recibida a las ${new Date().toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })}:\n${response.data}`)
          refresh();
        })
        .catch(error => {
          console.log(error);
          refresh();
        });
        
      }
      else{
        setUpdating('Nada que actualizar')
      }
    }

    const handleProcessRowUpdateError = React.useCallback((error) => {
      console.log(error.message);
    }, []);

    const mutateRow = (e) => {
      let nudata=[...data]
      let idx = nudata.findIndex((obj => obj?.product === e.product));
      nudata[idx]=e
      setData(nudata)
      return (e)
    };
    const processRowUpdate = React.useCallback(
      async (newRow) => {
        // Make the HTTP request to save in the backend
        const response = await mutateRow(newRow);
        return response;
      },
      [mutateRow],
    );



  const parpage2title= {'fastmarket':'Fast Market (old)','lastprice':'Last Price'}

  const parpage2cols = {
    'fastmarket': [
      { field: 'product', headerClassName: 'bg-cyan-500 text-white sticky top-0 p-2', align: 'center', headerAlign: 'center', headerName: 'Producto', width: 120, editable: false },
      { field: 'threshold_level1_lower', type: 'number', headerClassName: 'bg-cyan-500 text-white sticky top-0 p-2', align: 'center', headerAlign: 'center', headerName: ' [Umbral 1 I', width: 120, editable: true, description: 'Cota inferior del Umbral 1'},
      { field: 'threshold_level1_upper', type: 'number', headerClassName: 'bg-cyan-500 text-white sticky top-0 p-2', align: 'center', headerAlign: 'center', headerName: 'Umbral 1 S]', width: 120, editable: true, description: 'Cota superior del Umbral 1'},
      { field: 'threshold_level2_lower', type: 'number', headerClassName: 'bg-cyan-500 text-white sticky top-0 p-2', align: 'center', headerAlign: 'center', headerName: '[Umbral 2 I', width: 120, editable: true, description: 'Cota inferior del Umbral 2'},
      { field: 'threshold_level2_upper', type: 'number', headerClassName: 'bg-cyan-500 text-white sticky top-0 p-2', align: 'center', headerAlign: 'center', headerName: 'Umbral 2 S]', width: 120, editable: true, description: 'Cota superior del Umbral 2'},
      { field: 'threshold_level3_lower', type: 'number', headerClassName: 'bg-cyan-500 text-white sticky top-0 p-2', align: 'center', headerAlign: 'center', headerName: '[Umbral 3 I', width: 120, editable: true, description: 'Cota inferior del Umbral 3'},
      { field: 'threshold_level3_upper', type: 'number', headerClassName: 'bg-cyan-500 text-white sticky top-0 p-2', align: 'center', headerAlign: 'center', headerName: 'Umbral 3 S]', width: 120, editable: true, description: 'Cota superior del Umbral 3'},
      { field: 'threshold_level4_lower', type: 'number', headerClassName: 'bg-cyan-500 text-white sticky top-0 p-2', align: 'center', headerAlign: 'center', headerName: '[Umbral 4 I', width: 120, editable: true, description: 'Cota inferior del Umbral 4'},
      { field: 'threshold_level4_upper', type: 'number', headerClassName: 'bg-cyan-500 text-white sticky top-0 p-2', align: 'center', headerAlign: 'center', headerName: 'Umbral 4 S]', width: 120, editable: true, description: 'Cota superior del Umbral 5'},
      { field: 'maxspread_q75', type: 'number', headerClassName: 'bg-cyan-500 text-white sticky top-0 p-2', align: 'center', headerAlign: 'center', headerName: 'Spread Q75', width: 120, editable: true, description: 'Cuantil 75 del spread'},
      { field: 'maxspread_q90', type: 'number', headerClassName: 'bg-cyan-500 text-white sticky top-0 p-2', align: 'center', headerAlign: 'center', headerName: 'Spread Q90', width: 120, editable: true, description: 'Cuantil 90 del spread'},
    ],
    'lastprice': [
      { field: 'product', headerClassName: 'bg-cyan-500 text-white sticky top-0 p-2', align: 'center', headerAlign: 'center', headerName: 'Producto', width: 180, editable: false },
      { field: 'minsize_q25', type: 'number', headerClassName: 'bg-cyan-500 text-white sticky top-0 p-2', align: 'center', headerAlign: 'center', headerName: 'Cantidad Q25', width: 180, editable: true },
      { field: 'maxspread_q75', type: 'number', headerClassName: 'bg-cyan-500 text-white sticky top-0 p-2', align: 'center', headerAlign: 'center', headerName: 'Spread Q75', width: 180, editable: true },

    ]
  }

    return (
  
      <div className='grow min-w-full place-content-center'>
        <div className="font-medium leading-tight text-3xl mt-0 mb-2 text-indigo-900" ><h3>Parámetros {`${parpage2title[partoedit]}`}</h3></div>
  
        <button className="bg-emerald-500 rounded-lg py-2 px-10 text-white mt-10" onClick={() => handleClick()}>Actualizar Parámetros</button> 
        <p className={`py-2 px-5 text-white mt-2 mx-auto max-w-fit rounded-3xl ${updating ? 'bg-cyan-500/20 ' : ''}`}>{updating}</p>
        <div className="mt-10 mx-auto min-w-min max-w-fit">
        <DataGrid getRowId={(row) => `${row.product}_${row.dtime}`} 
        processRowUpdate={processRowUpdate} onProcessRowUpdateError={handleProcessRowUpdateError}
        getRowClassName={(params) => `${(params.indexRelativeToCurrentPage % 2) === 0 ? "" : "bg-blue-100"}`}
        rows={data} columns={parpage2cols[partoedit]} 
        hideFooterPagination hideFooterSelectedRowCount hideFooter />
        </div>
      </div>
    );
  }
  
  export default EditParameters;