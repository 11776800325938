import React, { useEffect, useState } from "react";
import {getAdminBoard} from "../services/user.service";
import EventBus from "../common/EventBus";
import Register from "./register.component";

const BoardAdmin = () => {
  const [content, setContent] = useState({});

  useEffect(() => {
    getAdminBoard().then(response => {
      setContent(response.data);
    }, error => {
      setContent((error.response && error.response.data && error.response.data.message) || error.message || error.toString());

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    });
  }, []);


  return <div className="container text-center flex min-w-full grow">
      <div className="container bg-blue-200 place-content-center space-x-1 my-4 grow min-w-fit">
        <div className="flex-row justify-center items-center py-4 min-w-fit">
            {content?.comp === "Registry" ? <Register /> : null}
        </div>
      </div>
    </div>;
};

export default BoardAdmin;