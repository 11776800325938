import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';

import "react-datepicker/dist/react-datepicker.css";
import { getSettleData, sendMibgas, setSettleData, reRunProcess, reRunExcel } from '../services/user.service';
import { dataByObkey } from '../common/extra';

registerLocale('es', es)


function sortTuple(s) {
  if (s.startsWith("GWDES")) {
    return [1, s];
  } else if (s.startsWith("GDAES")) {
    return [2, s.slice(8), s];
  } else if (s.startsWith("GWEES")) {
    return [3, s];
  } else if (s.startsWith("GBoMES")) {
    return [4, s];
  } else if (s.startsWith('GMAES')) {
    return [5, s];
  } else if (s.startsWith('GMES')) {
    return [6, s];
  } else if (s.startsWith('GQES')) {
    return [7, s];
  } else if (s.startsWith('GSES')) {
    return [8, s];
  } else if (s.startsWith('GYES')) {
    return [9, s];
  } else if (s.startsWith('GWDPT')) {
    return [10, s];
  } else if (s.startsWith('GDAPT')) {
    return [11, s.slice(8), s];
  } else if (s.startsWith('GWEPT')) {
    return [12, s];
  } else if (s.startsWith('WD_TVB')) {
    return [13, s];
  } else if (s.startsWith('DA_TVB')) {
    return [14, s];
  } else if (s.startsWith('D_TVB')) {
    return [15, s.slice(8), s];
  } else if (s.startsWith('BoM_TVB')) {
    return [16, s];
  } else if (s.startsWith('M_TVB')) {
    return [17, s];
  } else if (s.startsWith('WD_AVB')) {
    return [18, s];
  } else if (s.startsWith('DA_AVB')) {
    return [19, s];
  } else if (s.startsWith('PVB_TTF')) {
    if (s.length === 16) { //daily
      return [20, s.slice(10), s];
    } else if (s.length === 15) { //BoM
      return [21, s.slice(8), s];
    } else if (s.length === 12) {
      if (s[10] !== 'Q'){ //monthly
        return [22, s];
      } else { //quarter
        return [23, s];
      }
    } else if (s.length === 11) { //season
      return [24, s];
    } else { //anual
      return [25, s];
    }
  } else if (s.startsWith('PVB_LPI')) {
      if (s.length === 16) { //daily
        return [26, s.slice(10), s];
      } else if (s.length === 15) { //BoM
        return [27, s.slice(8), s];
      } else if (s.length === 12) {
        if (s[10] !== 'Q'){ //monthly
          return [28, s];
        } else { //quarter
          return [29, s];
        }
      } else if (s.length === 11) { //season
        return [30, s];
      } else { //anual
        return [31, s];
      }
  } else {
    return [32, s];
  }
}

function customSort(a, b) {
  const keyA = sortTuple(a);
  const keyB = sortTuple(b);
  for (let i = 0; i < keyA.length; i++) {
    if (keyA[i] < keyB[i]) {
      return -1;
    } else if (keyA[i] > keyB[i]) {
      return 1;
    }
  }
  return 0;
};

function getCurve(ticker) {
  const index = sortTuple(ticker)[0]
  if (index >= 1 && index <= 9){
    return 'Curva PVB'
  } else if (index >= 10 && index <= 12){
    return 'Curva VTP'
  } else if (index >= 13 && index <= 17){
    return 'Curva TVB'
  } else if (index >= 18 && index <= 19){
    return 'Curva AVB'
  } else if (index >= 20 && index <= 25){
    return 'Curva Spread PVB-TTF'
  } else if (index >= 26 && index <= 31){
    return 'Curva PVB Last Price Index'
  } else {
    return 'Otros productos'
  }
}

function MyTable(props) {
  const [data, setData] = useState([]);
  const [origdata, setOrigData] = useState([]);
  const [databyCurve, setDatabyCurve] = useState(new Map()); // es una copia de data pero ordenado por curvas
  const [startDate, setStartDate] = useState(new Date());
  const [customSettles, setcustomSettles] = useState({});
  const [dropdownValue, setDropdownValue] = useState({});
  const [sentmib, setSentMib] = useState('');
  const [rerun, setReRun] = useState('');
  const [rerunOMIPExcel, setReRunOMIPExcel] = useState('');

  const warning_dict = {'leve':' bg-amber-200', 'grave':' bg-orange-200'}
  

  const refresh = (mydate) => {
    getSettleData(mydate)
      .then(res => {
        setcustomSettles({});
        setDropdownValue({});
        let sortedData = res.data.sort((a, b) => customSort(a.instrument, b.instrument))
        setData(sortedData);
        setOrigData(sortedData.map(item => Object.assign({}, item)));
        sortedData.forEach(aux => { aux.curve = getCurve(aux.instrument); }); // add curve key
        let lpbyCurve = dataByObkey(sortedData, 'curve'); // group by curve
        lpbyCurve.forEach((valueList, key) => { valueList.forEach(dict => { delete dict['curve']; }); }); // remove curve key
        setDatabyCurve(lpbyCurve)
      })
      .catch(err => console.log(err));
  }

  const batchsendDataToBackend = (updaterows) =>  {
    setSettleData(updaterows)
      .then(() => {
        refresh(startDate)
      })
      .catch(error => {
        console.log(error)
      });
  }


  useEffect(() => {
    refresh(startDate)
  }, [startDate]);

  const handleDropdownChange = useCallback((event, index) => {
    const modifer = {"settle_price1":{"bid":"settle_bid1","ask":"settle_ask1", "quality":1}, "settle_price2":{"bid":"settle_bid2","ask":"settle_ask2", "quality":2}, 
    "settle_price3":{"bid":"settle_bid3","ask":"settle_ask3", "quality":3}, "settle_tullet":{"quality":4}, "settle_omip":{"quality":5}, "custom":{"quality":20}, "remove":""}

    let newDrop = {...dropdownValue};
    let newData = [...data];
    let targetVal = event.target.value;
    let params=modifer[targetVal]

    if (index !== -1)
    {
      newDrop[index] = targetVal;
    }
    
    setDropdownValue(newDrop);

    if (targetVal === 'custom')
    {
      Object.keys(customSettles).forEach(ind => {
        newData = updateNewData(newData, ind, params, customSettles[ind]);
      });
    }
    else
    {
      if (targetVal === 'remove')
      {
        newData = updateNewData(newData, index, params, null)
      }
      else
      {
        newData = updateNewData(newData, index, params, newData[index][targetVal])
      }
      if (customSettles.hasOwnProperty(index)){
        delete customSettles[index]
      }
    }

    setData(newData);
    newData.forEach(aux => { aux.curve = getCurve(aux.instrument); }); // add curve key
    let lpbyCurve = dataByObkey(newData, 'curve'); // group by curve
    lpbyCurve.forEach((valueList, key) => { valueList.forEach(dict => { delete dict['curve']; }); }); // remove curve key
    setDatabyCurve(lpbyCurve)

  }, [dropdownValue, data, customSettles]);


  useEffect(() => {
    let timeoutId;
    if (Object.keys(customSettles).length !== 0) {
      timeoutId = setTimeout(() => {
        // Access the updated input values here
        handleDropdownChange({target:{value:'custom'}}, -1);
      }, 1000);  // 1000ms = 1 second
    }
    return () => clearTimeout(timeoutId);
  }, [customSettles, handleDropdownChange]);

  const updateNewData = (newData, ind, params, settle) => {
    
    if (params.hasOwnProperty('bid')) {
      newData[ind]['settle_bid'] = newData[ind][params['bid']]
    }
    else {
      newData[ind]['settle_bid'] = null
    }

    if (params.hasOwnProperty('ask')) {
      newData[ind]['settle_ask'] = newData[ind][params['ask']]
    }
    else {
      newData[ind]['settle_ask'] = null
    }

    if (settle != null && params.hasOwnProperty('quality')) {
      newData[ind]['settle_quality_factor'] = params['quality']
    }
    else {
      newData[ind]['settle_quality_factor'] = null
    }
    newData[ind]['settle'] = settle
    return newData;
  }


  const handleInputChange = (event, index) => {
    let newCustom = {...customSettles};
    let candidate = event.target.value;
    //handle different format
    candidate = candidate.replace(',', '.')

    candidate = parseFloat(candidate);

    newCustom[index] = isNaN(candidate) ? null : candidate;
    setcustomSettles(newCustom);
  };


  const handleDate = (date, ) => {
    setStartDate(date);
    refresh(date)
  }

  const compareArrays = (original, currentData) => {
    const result = [];
    original.forEach((originalObject, index) => {
      const updatedObject = currentData[index];
      const updaterobj = {};
      Object.keys(originalObject).forEach(key => {
        if (originalObject[key] !== updatedObject[key]) {
          updaterobj[key]=updatedObject[key];
        }
      });
      if (Object.keys(updaterobj).length !== 0)
      {
        updaterobj.dtime=originalObject.dtime;
        updaterobj.instrument=originalObject.instrument;
        result.push(updaterobj);
      }
    });
    return result;
  }


  const handleClick = ()=> {
    let valueUpdater = compareArrays(origdata, data);
    batchsendDataToBackend(valueUpdater);
  }

  const handleClickSendMib = (mydate)=> {
    sendMibgas(mydate).then(response => {
      setSentMib(`Envío del WS del día ${mydate.getDate()}/${mydate.getMonth()}/${mydate.getFullYear()} finalizado a las ${response.data.time}. Estado final: ${response.data.state==='success' ? 'éxito': 'error' }.${response?.data?.returnvalue ? '\nRespuesta recibida: ' + response?.data?.returnvalue + '.' : ''}`)
    }).catch(error => {
      console.log(error);
    });
    setSentMib(`Envío del WS del día ${mydate.getDate()}/${mydate.getMonth()}/${mydate.getFullYear()} iniciado a las ${new Date().toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })}.`)
  }

  const handleClickReRun = (mydate)=> {
    reRunProcess(mydate).then(response => {
      setReRun(`Rejecución del día ${mydate.getDate()}/${mydate.getMonth()}/${mydate.getFullYear()} finalizada a las ${response.data.time}. Estado final: ${response.data.state==='success' ? 'éxito': 'error' }.${response?.data?.returnvalue ? '\nRespuesta recibida: ' + response?.data?.returnvalue + '.' : ''}`)
      refresh(startDate)
    }).catch(error => {
      console.log(error);
    });
    setReRun(`Rejecución del día ${mydate.getDate()}/${mydate.getMonth()}/${mydate.getFullYear()} iniciada a las ${new Date().toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })}.`)
  }

  const handleClickReRunExcel = (mydate)=> {
    reRunExcel(mydate).then(response => {
      setReRunOMIPExcel(`Envío del Excel a OMIP y OmiClear día ${mydate.getDate()}/${mydate.getMonth()}/${mydate.getFullYear()} finalizado a las ${response.data.time}. Estado final: ${response.data.state==='success' ? 'éxito': 'error' }.${response?.data?.returnvalue ? '\nRespuesta recibida: ' + response?.data?.returnvalue + '.' : ''}`)
    }).catch(error => {
      console.log(error);
    });
    setReRunOMIPExcel(`Envío del Excel a OMIP y OmiClear del día ${mydate.getDate()}/${mydate.getMonth()}/${mydate.getFullYear()} iniciado a las ${new Date().toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })}.`)
  }

  const columnNames = {
    "dtime": "Sesión",
    "instrument": "Producto",
    "settle_price1": "Precio 1",
    "settle_bid1": "Bid 1",
    "settle_ask1": "Ask 1",
    "settle_price2": "Precio 2",
    "settle_bid2": "Bid 2",
    "settle_ask2": "Ask 2",
    "settle_price3": "Precio 3",
    "settle_bid3": "Bid 3",
    "settle_ask3": "Ask 3",
    "settle_tullet": "Precio 4",
    "settle_omip": "OMIP",
    "settle": "Precio Último",
    "settle_bid": "Bid Último",
    "settle_ask": "Ask Último",
    "settle_quality_factor": "Factor de Calidad",
    "settle_modified": "Modificar Precio Último",
    "settle_reference_time": "Inicio Ventana Precio 1",
    "settle_last_modification": "Última Modificación"
}
  const settlecols= ["settle_price1", "settle_price2", "settle_price3", "settle_tullet", "settle_omip"]
  const deccols = ["settle_price1", "settle_bid1", "settle_ask1", "settle_price2", "settle_bid2", "settle_ask2", "settle_price3", "settle_bid3", "settle_ask3", "settle_tullet", "settle_omip", "settle", "settle_bid", "settle_ask"]

  return (

    <div className='grow min-w-full'>
      <div className="font-medium leading-tight text-3xl mt-0 mb-2 text-indigo-900" ><h3>Precio Último</h3></div>
      <DatePicker locale="es" dateFormat="dd/MM/yyyy" selected={startDate} onChange={handleDate} />

      {props.isad ? <>
      <button className="bg-emerald-500 rounded-lg py-2 px-10 text-white mt-10 mx-10" title="Guardar cambios de Precio Último" onClick={() => handleClick()}>Guardar Precios</button>

      <button className="bg-amber-700 rounded-lg py-2 px-10 text-white mt-10 mx-10" title="Enviar a la Plataforma de MIBGAS" onClick={() => handleClickSendMib(startDate)}>Enviar a MIBGAS</button>

      <button className="bg-orange-400 rounded-lg py-2 px-10 text-white mt-10 mx-10" title="Rejecutar el proceso de Precio Último" onClick={() => handleClickReRun(startDate)}>Rejecutar Proceso</button>
      
      <button className="bg-blue-700 rounded-lg py-2 px-10 text-white mt-10 mx-10" title="Enviar Excel a OMIP y OmiClear" onClick={() => handleClickReRunExcel(startDate)}>Enviar Excel</button>
      <p className={`py-2 px-5 text-white mt-2 mx-auto max-w-fit rounded-3xl ${sentmib ? 'bg-cyan-500/20 ' : ''}`}>{sentmib}</p>
      <p className={`py-2 px-5 text-white mt-2 mx-auto max-w-fit rounded-3xl ${rerun ? 'bg-cyan-500/20 ' : ''}`}>{rerun}</p>
      <p className={`py-2 px-5 text-white mt-2 mx-auto max-w-fit rounded-3xl ${rerunOMIPExcel ? 'bg-cyan-500/20 ' : ''}`}>{rerunOMIPExcel}</p>
      </> : null
      }
      <div>
        {(databyCurve.size === 0) ? <div className="font-medium leading-tight text-xl mt-10 mb-2 text-indigo-900" ><h5>No data for this day</h5></div> : null}
        {[...databyCurve.entries()].map(([curvekey, dataCurve]) => {
          return (
            <div className="mt-10">
              <div className="font-medium leading-tight text-2xl mt-0 mb-2 text-indigo-900" ><h4>{curvekey}</h4></div>
              <div className="max-h-[20rem] overflow-y-auto"></div>
              <table className="table-auto mt-10">
                <thead>
                  <tr className="bg-cyan-500 text-white sticky top-0">
                    {Object.keys(columnNames).map((key) => <th className="text-center py-2 px-3" key={key}>{columnNames[key]}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {dataCurve.map((row, index) => (
                    <tr key={row.id} className={(index % 2) === 0 ? "" : "bg-blue-100"}>
                      {Object.entries(row).map(([key, cell]) => {
                        if (key === 'settle_reference_time') {
                          if (cell != null) {
                            let date = new Date(cell)
                            return <td className="p-2">{date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', hour12: false })}</td>
                          }
                          else {
                            return <td className="p-2" />
                          }
                        } else if (key === 'mibeod_warnings'){
                          return null
                        }else if (key === 'settle_last_modification') {
                          if (cell != null) {
                            let date = new Date(cell)
                            return <td className="p-2">{date.toLocaleString('es-ES', {})}</td>
                          }
                          else {
                            return <td className="p-2" />
                          }
                        } else if (key === 'settle_modified') {
                          // let content = cell === null ? '' : cell
                          let nonnullcols = settlecols.filter((column) => row[column] !== null);
                          let index = data.findIndex(dict => dict.instrument === row.instrument)
                          return <td className="p-2">
                            {/* <input className="text-center" value={content} onChange={(e) => handleChange(e, key, index)} /> */}
                            <div>
                              <select className='overflow-hidden w-[10rem]'value={dropdownValue.hasOwnProperty(index) ? dropdownValue[index] : ''} onChange={(e) => handleDropdownChange(e, index)}>
                                <option value="" selected disabled hidden>Modificar</option>
                                {
                                  nonnullcols.map((column) => (
                                    <option key={columnNames[column]} value={column}>
                                      {columnNames[column]}:  {row[column]}
                                    </option>
                                  ))
                                }
                                <option value="custom">
                                  Modificar {customSettles.hasOwnProperty(index) ? customSettles[index] : ''}
                                </option>
                                <option value="remove">
                                  Eliminar
                                </option>
                              </select>
                              {dropdownValue.hasOwnProperty(index) && dropdownValue[index] === "custom" && (
                                <input type="number" name={index} value={customSettles.hasOwnProperty(index) ? customSettles[index] : ''} onChange={(e) => handleInputChange(e, index)} />
                              )}
                            </div>
                          </td>
                        } else {
                          let classname;
                          let title;
                          if (['instrument','settle'].includes(key)  && row.mibeod_warnings!== null) {
                            classname =`p2 rounded ${warning_dict[row.mibeod_warnings]}`
                            title = `Nivel de Aviso: ${row.mibeod_warnings}`
                          }
                          else{
                            classname ='p2'
                            title = ''
                          }
                          return <td className={classname} title={title}>{(deccols.includes(key) && parseFloat(cell)) ? parseFloat(cell).toFixed(3):cell}</td>
                        }
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>)
        })}
      </div>
    </div>);
}

export default MyTable;