import React from "react";
import { useState, useEffect } from "react";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import {
  getFMParametersData,
  setFMParametersData,
} from "../services/user.service";
import { customSort } from "../functions/sort.function";
import { useLocation, useParams } from "react-router-dom";
import {
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";

registerLocale("es", es);

function databyInstrument(data) {
  return data.reduce((map, obj, idx) => {
    const inst = obj.product;
    if (!map.has(inst)) {
      map.set(inst, []);
    }
    map.get(inst).push({ ...obj, id: idx });

    return map;
  }, new Map());
}

function compareMaps(original, current) {
  const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      const obj1 = arr1[i];
      const obj2 = arr2[i];

      // We want to consider 0.4 and '0.4' as equals
      // eslint-disable-next-line eqeqeq
      if (obj1.start_level != obj2.start_level) {
        return false;
      }

      for (const obkey in obj1) {
        // We want to consider 0.4 and '0.4' as equals
        // eslint-disable-next-line eqeqeq
        if (obkey !== "id" && obj1[obkey] != obj2[obkey]) {
          return false;
        }
      }
    }

    return true;
  };

  let changedprods = [];

  original.forEach((val, key) => {
    let nuval = current.get(key);

    if (!areArraysEqual(val, nuval)) {
      changedprods.push(key);
    }
  });
  return changedprods;
}

function validateRows(prodName, rows) {
  let errors = [];
  let start_levels = [...new Set(rows.map((x) => parseFloat(x.start_level)))];

  if (start_levels.length < rows.length) {
    errors.push(
      `Para ${prodName} hay elementos con Inicio de rango de precio repetido.`
    );
  }
  if (start_levels.some((x) => isNaN(x))) {
    errors.push(
      `Para ${prodName} hay elementos con un Inicio de rango de precio no válido.`
    );
  }
  return errors;
}

function getNewRows(original, current) {
  let changedprods = compareMaps(original, current);

  let newRows = [];

  let validatingerrors = [];

  changedprods.forEach((prodName) => {
    if (current.has(prodName)) {
      const currentRows = current.get(prodName);
      validatingerrors = [
        ...validatingerrors,
        ...validateRows(prodName, currentRows),
      ];
      currentRows.forEach((obj) => {
        const { id, ...rest } = obj; // Destructure to remove 'foo' key
        newRows.push(rest);
      });
    }
  });
  return [newRows, validatingerrors];
}

const theme = createTheme({
  mixins: {
    MuiDataGrid: {
      containerBackground: "#06b6d4",
    },
  },
});

function addR(oldRows, prodName) {
  let basedata = new Map(oldRows);
  let nuRows;
  if (basedata.has(prodName)) {
    let prodRows = basedata.get(prodName);
    nuRows = [
      ...prodRows,
      {
        product: prodName,
        id: Math.max(...prodRows.map((obj) => obj.id)) + 1,
        isNew: true,
      },
    ];
  } else {
    nuRows = [{ product: prodName, id: 0, isNew: true }];
  }
  basedata.set(prodName, nuRows);
  return basedata;
}

function delR(oldRows, prodName, id) {
  let basedata = new Map(oldRows);
  let nuRows = basedata.get(prodName).filter((row) => row.id !== id);
  basedata.set(prodName, nuRows);
  return basedata;
}

const CustomToolbar = ({ prodName, setter }) => {
  const addRow = () => {
    setter((oldRows) => addR(oldRows, prodName));
  };

  return (
    <GridToolbarContainer sx={{ position: "absolute", top: -25, zIndex: 1040 }}>
      <button
        onClick={addRow}
        className="bg-cyan-300 rounded-lg py-0 px-3 text-white mt-10"
      >
        Añadir entrada
      </button>
    </GridToolbarContainer>
  );
};

function ParametersFM() {
  const [prods, setProds] = useState([]);
  const [dataMar, setDataMar] = useState(new Map());
  const [dataThres, setDataThres] = useState(new Map());
  const [origdataMar, setOrigDataMar] = useState(new Map());
  const [origdataThres, setOrigDataThres] = useState(new Map());
  const [updating, setUpdating] = useState("");

  const partype2Setter = { margins: setDataMar, thresholds: setDataThres };

  let { parpage } = useParams();
  let location = useLocation();

  const refresh = () => {
    getFMParametersData()
      .then((res) => {
        let dbdata = JSON.parse(res.data);

        let dbdatamargin = databyInstrument(dbdata.margins);

        let dbdatathres = databyInstrument(dbdata.thresholds);

        setProds(
          [...new Set([...dbdatamargin.keys(), ...dbdatathres.keys()])].sort(
            (a, b) => customSort(a, b)
          )
        );

        setDataMar(dbdatamargin);
        setOrigDataMar(new Map(dbdatamargin));

        setDataThres(dbdatathres);
        setOrigDataThres(new Map(dbdatathres));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    refresh();
  }, [location, parpage]);

  const saveParams = () => {
    // here we want to compare dataMar to origdataMar and dataThres to origdataThres
    // We need a function that for each product, checks if there are any changes and return a list of products.
    // for the changed products we will add the rows to a list for the partype and send that to change
    let [newRowsMargins, errorsMargins] = getNewRows(origdataMar, dataMar);
    let [newRowsThresholds, errorsThresholds] = getNewRows(
      origdataThres,
      dataThres
    );
    let thepackage = {};
    if (newRowsMargins.length > 0) {
      thepackage.margins = newRowsMargins;
    }
    if (newRowsThresholds.length > 0) {
      thepackage.thresholds = newRowsThresholds;
    }

    if (errorsMargins.length > 0 || errorsThresholds.length > 0) {
      let errorsstr =
        "Hemos encontrado errores.\n" +
        (errorsMargins.length > 0
          ? `Para spread bid-ask:\n ${errorsMargins.join("\n")}\n`
          : "") +
        (errorsThresholds.length > 0
          ? `Para umbral de variación:\n ${errorsThresholds.join("\n")}`
          : "");
      console.log(errorsstr);
      setUpdating(errorsstr);
    } else if (Object.keys(thepackage).length > 0) {
      setUpdating(
        `Guardando parámetros a las ${new Date().toLocaleTimeString("es-ES", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        })}.`
      );
      setFMParametersData(thepackage)
        .then((response) => {
          setUpdating(
            `Respuesta recibida a las ${new Date().toLocaleTimeString("es-ES", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: false,
            })}:\n${response.data}`
          );
          refresh();
        })
        .catch((error) => {
          console.log(error);
          refresh();
        });
    } else {
      setUpdating("Nada que actualizar");
    }
  };

  const handleProcessRowUpdateError = React.useCallback((error) => {
    console.log(error.message);
  }, []);

  const processRowUpdate = React.useCallback(
    async (prodName, basedata, setter, newRow, oldRow) => {
      let prodRows;
      if (basedata.has(prodName)) {
        prodRows = [...basedata.get(prodName)];
        let idx = prodRows.findIndex((obj) => obj?.id === newRow.id);
        prodRows[idx] = newRow;
        prodRows.sort((a, b) => a.start_level - b.start_level);
      } else {
        prodRows = [newRow];
      }

      basedata.set(prodName, prodRows);

      setter(basedata);

      return newRow;
    },
    []
  );

  const deleteEntry = React.useCallback(
    (params, setter) => () => {
      setTimeout(() => {
        const prodName = params.id.split("🦕")[0];

        setter((oldRows) => delR(oldRows, prodName, params.row.id));
      });
    },
    []
  );

  const parpage2title = { fastmarket: "Fast Market", lastprice: "Last Price" };
  const numval = (x) => {
    return isNaN(parseFloat(x));
  };

  const partype2cols = {
    margins: [
      {
        field: "product",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Producto",
        width: 120,
        editable: false,
      },
      {
        field: "start_level",
        type: "number",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Inicio rango de precio",
        width: 160,
        editable: true,
        description: "Inicio rango de precio",
        cellClassName: (params) => (numval(params.value) ? "bg-red-300" : ""),
      },
      {
        field: "margin_sm",
        type: "number",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Stressed Market",
        width: 120,
        editable: true,
        description: "Stressed Market",
      },
      {
        field: "margin_fm",
        type: "number",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Fast Market",
        width: 120,
        editable: true,
        description: "Fast Market",
      },
      {
        field: "actions",
        type: "actions",
        width: 20,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon={faDeleteLeft} />}
            label="Delete"
            onClick={deleteEntry(params, setDataMar)}
          />,
        ],
      },
    ],
    thresholds: [
      {
        field: "product",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Producto",
        width: 120,
        editable: false,
      },
      {
        field: "start_level",
        type: "number",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Inicio rango de precio",
        width: 160,
        editable: true,
        description: "Inicio rango de precio",
        cellClassName: (params) => (numval(params.value) ? "bg-red-300" : ""),
      },
      {
        field: "threshold_sm",
        type: "number",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Stressed Market",
        width: 120,
        editable: true,
        description: "Stressed Market",
      },
      {
        field: "threshold_fm",
        type: "number",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        align: "center",
        headerAlign: "center",
        headerName: "Fast Market",
        width: 120,
        editable: true,
        description: "Fast Market",
      },
      {
        field: "actions",
        type: "actions",
        width: 20,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon={faDeleteLeft} />}
            label="Delete"
            onClick={deleteEntry(params, setDataThres)}
          />,
        ],
      },
    ],
  };

  const columnGroupingModel = {
    margins: [
      {
        groupId: "Amplitud spread bid-ask (€/MWh)",
        description: "",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        children: [{ field: "margin_sm" }, { field: "margin_fm" }],
      },
    ],
    thresholds: [
      {
        groupId: "Umbral variación precios (€/MWh)",
        headerClassName: "bg-cyan-500 text-white sticky top-0 p-2",
        description: "",
        children: [{ field: "threshold_sm" }, { field: "threshold_fm" }],
      },
    ],
  };

  return (
    <div className="grow min-w-full place-content-center">
      <div className="font-medium leading-tight text-3xl mt-0 mb-2 text-indigo-900">
        <h3>Parámetros {`${parpage2title[parpage]}`}</h3>
      </div>

      <button
        className="bg-emerald-500 rounded-lg py-2 px-10 text-white mt-10"
        onClick={() => saveParams()}
      >
        Guardar Cambios
      </button>
      <pre
        className={`py-2 px-5 text-white mt-2 mx-auto max-w-fit rounded-3xl ${
          updating ? "bg-cyan-500/20 " : ""
        }`}
      >
        {updating}
      </pre>
      <div className="mt-10 mx-auto min-w-min max-w-fit">
        {prods.map((prodName) => (
          <div key={prodName}>
            <p className="h2 mt-4 mb-2 pt-5 pb-3 font-semibold text-lg">
              {prodName}
            </p>
            <div className="columns-2 gap-6">
              {["margins", "thresholds"].map((partype) => (
                <>
                  <div key={partype} className="grow break-after-all">
                    <ThemeProvider theme={theme}>
                      <DataGrid
                        sx={{ minHeight: 150 }}
                        getRowId={(row) =>
                          `${row.product}🦕${row.start_level}🦕${partype}🦕${row.id}`
                        }
                        processRowUpdate={(newRow, oldRow) =>
                          processRowUpdate(
                            prodName,
                            partype === "margins"
                              ? new Map(dataMar)
                              : new Map(dataThres),
                            partype2Setter[partype],
                            newRow,
                            oldRow
                          )
                        }
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        getRowClassName={(params) =>
                          `${
                            params.indexRelativeToCurrentPage % 2 === 0
                              ? ""
                              : "bg-blue-100"
                          }`
                        }
                        rows={
                          partype === "margins"
                            ? dataMar.has(prodName)
                              ? dataMar.get(prodName)
                              : []
                            : dataThres.has(prodName)
                            ? dataThres.get(prodName)
                            : []
                        }
                        columns={partype2cols[partype]}
                        columnGroupingModel={columnGroupingModel[partype]}
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        hideFooter
                        slots={{
                          toolbar: CustomToolbar,
                        }}
                        slotProps={{
                          toolbar: {
                            prodName,
                            setter: partype2Setter[partype],
                          },
                        }}
                      />
                    </ThemeProvider>
                  </div>
                  <div className="grow break-after-column"></div>
                </>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ParametersFM;
