const dataByObkey = (rawData, key, replacementdict = {}) => {

    return rawData.reduce((newData, obj) => {
      const oldkey = obj[key]
      const newkey = ( oldkey in replacementdict)  ? replacementdict[oldkey]: oldkey;
  
      if (!newData.has(newkey)) {
        newData.set(newkey, []);
      }
  
      newData.get(newkey).push(obj);
  
      return newData;
    }, new Map());
  
  }

  
export {
    dataByObkey
}