import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {getFastMarketBoard} from "../services/user.service";
import EventBus from "../common/EventBus";
import Parameters from "./parameters.component";
import ParametersFM from "./parametersfm.component";
import RunParametes from "./runparms.component";
import EditParameters from "./editparameters.component";

const BoardParams = () => {
  const [content, setContent] = useState({});
  let { parpage } = useParams();

  useEffect(() => {
    getFastMarketBoard().then(response => {
      setContent(response.data);
    }, error => {
      setContent((error.response && error.response.data && error.response.data.message) || error.message || error.toString());

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    });
  }, []);
  
  return <div className="container text-center flex min-w-full grow">
        <div className="container bg-blue-200 place-content-center space-x-1 my-4 grow min-w-fit">
          <div className="flex-row justify-center items-center py-4 min-w-fit">
              {content.comp === "FastMarket" ? 
                (parpage === 'calc' ? 
                  <RunParametes/> :  
                    (parpage === 'edit'? 
                      <EditParameters/> :
                      ( parpage === 'fastmarket'? 
                        <ParametersFM/> :
                      <Parameters />
                    )
                    )
                ) 
              : null}
          </div>
        </div>
      </div>;
};

export default BoardParams;