import React, { useEffect, useState } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { isEmail } from "validator";
import { register } from "../services/auth.service";

import { getData, updateUserRole, deleteUser } from "../services/user.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        ¡Este campo es obligatorio!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Esto no es un correo electrónico válido.
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        El nombre de usuario debe tener entre 3 y 20 caracteres.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        La contraseña debe tener entre 6 y 40 caracteres.
      </div>
    );
  }
};

const Register = ({ handleClose }) => {
  /* Component to register a new user */
  const [username, setUsername] = useState("");
  const [emailT, setEmailT] = useState("");
  const [password, setPassword] = useState("");

  const [message, setMessage] = useState("");
  const [successful, setSuccessful] = useState(false);

  const handleRegister = (e) => {
    e.preventDefault();
    setMessage("");
    setSuccessful(false);

    let email_valid = email(emailT);
    let user_valid = vusername(username);
    let pass_valid = vpassword(password);

    if (!email_valid && !user_valid && !pass_valid) {
      register(username, emailT, password).then(
        (response) => {
          setMessage(response.data.message);
          setSuccessful(true);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setSuccessful(false);
          setMessage(resMessage);
        }
      );
    }
  };

  const handleCancel = () => {
    setUsername("");
    setEmailT("");
    setPassword("");
    setMessage("");
    setSuccessful(false);
    handleClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="card card-container bg-blue-100 mx-auto">
        <div className=" font-medium leading-tight text-3xl mt-2 mb-5 text-indigo-900">
          <h3>Registrar Usuario</h3>
        </div>
        <Form onSubmit={handleRegister}>
          {!successful && (
            <div>
              <div className="form-group">
                <label htmlFor="username">Nombre de usuario</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  validations={[required, vusername]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={emailT}
                  onChange={(e) => setEmailT(e.target.value)}
                  validations={[required, email]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Contraseña</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  validations={[required, vpassword]}
                />
              </div>
              <div className="form-group flex space-x-4">
                <div className="form-group">
                  <button
                    className="bg-emerald-500 rounded-lg py-2 px-10 text-white mt-10"
                    onClick={handleRegister}
                  >
                    Registrar
                  </button>
                </div>
                <div className="form-group">
                  <button
                    className="bg-red-500 rounded-lg py-2 px-10 text-white mt-10"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
              <button
                className="bg-blue-500 rounded-lg py-2 px-10 text-white mt-10"
                onClick={handleCancel}
              >
                Cerrar
              </button>
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

const columnNames = {
  ID: "id",
  Usuario: "username",
  Email: "email",
  Rol: "role",
};

const ConfirmationDeleteUserModal = ({
  userName,
  handleClose,
  handleDelete,
}) => {
  /* Component to confirm the deletion of a user */
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="card card-container bg-blue-100 mx-auto">
        <div className=" font-medium leading-tight text-3xl mt-2 mb-5 text-indigo-900">
          <h3>Confirmar Eliminación de Usuario</h3>
        </div>
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            ¿Estás seguro de que deseas eliminar a este usuario: {userName}?
          </div>
          <div className="form-group flex space-x-4">
            <div className="form-group">
              <button
                className="bg-emerald-500 rounded-lg py-2 px-10 text-white mt-10"
                onClick={handleDelete}
              >
                Eliminar
              </button>
            </div>
            <div className="form-group">
              <button
                className="bg-red-500 rounded-lg py-2 px-10 text-white mt-10"
                onClick={handleClose}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserManagement = () => {
  /* Component to manage users. It consists of a table with the list of users and 
  multiple buttons and dropdowns for interact with users data. */
  const [userData, setUserData] = useState([]);
  const [openNewUser, setOpenNewUser] = useState(false);

  const handleDropdownChange = (e, key, it) => {
    const newRole = e.target.value;
    updateUserRole(key, newRole)
      .then((response) => {
        const updatedUserData = userData.map((user) => {
          if (user.id === key) {
            user.role = newRole;
          }
          return user;
        });
        setUserData(updatedUserData);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const renderCellWithDropdown = (key, it) => {
    const value = userData.find((user) => user.id === key).role;
    return (
      <select
        className="form-select"
        onChange={(e) => handleDropdownChange(e, key, it)}
        value={value}
      >
        <option value="admin">Admin</option>
        <option value="user">User</option>
        <option value="operator">Operator</option>
      </select>
    );
  };

  const handleAddNewUser = () => {
    setOpenNewUser(true);
  };

  useEffect(() => {
    // Fetch data from the server
    getData("users")
      .then((response) => {
        setUserData(response.data);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const closeRegisterForm = () => {
    setOpenNewUser(false);
  };

  const handleDeleteUser = (id) => {
    deleteUser(id)
      .then((response) => {
        const updatedUserData = userData.filter((user) => user.id !== id);
        setUserData(updatedUserData);
        handleCloseConfirmationModal();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const handleOpenConfirmationModal = (userID) => {
    setOpenConfirmationModal(true);
    setUserIdToDelete(userID);
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  // Show users table
  return (
    <div className="grow min-w-full place-content-center">
      <div className="font-medium leading-tight text-3xl mt-0 mb-2 text-indigo-900">
        <h3>Gestión de Usuarios</h3>
      </div>
      {/* Add new user button */}
      <div className="flex justify-center">
        <button
          className="bg-emerald-500 rounded-lg py-2 px-5 text-white mt-5"
          onClick={handleAddNewUser}
        >
          Registrar nuevo usuario
        </button>
      </div>
      <table className="table-auto mt-10 mx-auto">
        <thead>
          <tr className="bg-cyan-500 text-white sticky top-0">
            {Object.keys(columnNames).map((key) => (
              <th key={key} className="text-center p-2">
                {key}
              </th>
            ))}
            <th className="text-center p-2">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {userData.map((row, index) => (
            <tr key={row.id} className={index % 2 === 0 ? "" : "bg-blue-100"}>
              {Object.keys(columnNames).map((key) => (
                <td key={key} className="p-2">
                  {key === "Rol"
                    ? renderCellWithDropdown(row.id, key)
                    : row[columnNames[key]]}
                </td>
              ))}
              <td>
                <button
                  className="rounded-lg py-2 px-1 text-white mt-0 border-2 border-red-500 text-red-500 bg-white"
                  onClick={() => handleOpenConfirmationModal(row.id)}
                >
                  Eliminar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {openNewUser && <Register handleClose={closeRegisterForm} />}
      {openConfirmationModal && (
        <ConfirmationDeleteUserModal
          userName={
            userData.find((user) => user.id === userIdToDelete).username
          }
          handleClose={handleCloseConfirmationModal}
          handleDelete={() => handleDeleteUser(userIdToDelete)}
        />
      )}
    </div>
  );
};

export default UserManagement;
