function sortTuple(s) {
    if (s.startsWith("GWDES")) {
      return [1, s];
    } else if (s.startsWith("GDAES")) {
      return [2, s.slice(8), s];;
    } else if (s.startsWith("GWDPT")) {
      return [3, s];
    } else if (s.startsWith("GDAPT")) {
      return [4, s.slice(8), s];;
    } else if (s.startsWith('GWEES')) {
      return [5, s];
    } else if (s.startsWith('GWEPT')) {
      return [6, s];
    } else if (s.startsWith('WD_TVB')) {
      return [7, s];
    } else if (s.startsWith('DA_TVB')) {
      return [8, s];
    } else if (s.startsWith('WD_AVB')) {
      return [9, s];
    } else if (s.startsWith('DA_AVB')) {
      return [10, s];
    } else if (s.startsWith('GBoMES')) {
      return [11, s];
    } else if (s.startsWith('GMAES')) {
      return [12, s];
    } else if (s.startsWith('GMES')) {
      return [13, s];
    } else if (s.startsWith('GQES')) {
      return [14, s];
    } else if (s.startsWith('GSES')) {
      return [15, s];
    } else if (s.startsWith('GYES')) {
      return [16, s];
    } else {
      return [17, s];
    }
  }
  
  function customSort(a, b) {
    const keyA = sortTuple(a);
    const keyB = sortTuple(b);
    for (let i = 0; i < keyA.length; i++) {
      if (keyA[i] < keyB[i]) {
        return -1;
      } else if (keyA[i] > keyB[i]) {
        return 1;
      }
    }
    return 0;
  };

  export {
    customSort,
  };